import React from 'react';
import {
    formatter, formatterDecimal
} from "../../../../utils/common";

const Widget = ({ textColorClass, value, label }) => {
    textColorClass = textColorClass ? textColorClass : 'text-dark';
    return (
        <div className="col-md-6 col-xl">
            <div className={`widget-rounded-circle card-box`}>
                <div className="text-right">
                    <h5 className={`mt-1 ${textColorClass}`}>
                        <span>{value}</span>
                    </h5>
                    <p className="text-muted mb-1 text-truncate">{label}</p>
                </div>
            </div>
        </div>
    );
};

const DiscountTotalSummary = ({ financesSummaryState }) => {
    const totalRow = financesSummaryState.discountDetailsTableRow;
    const totalSummary = financesSummaryState.discountDetailsTableSummary;

    const widgetsData = [
        {
            value: totalRow,
            label: "Total Records",
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_discount : 0),
            label: "Total Discount",
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_tax_discount : 0),
            label: "Total Tax",
        },
        {
            textColorClass: 'text-success',
            value: formatter.format(totalSummary ? totalSummary.total_net_discount : 0),
            label: "Total Net Discount",
        },
        // {
        //     value: formatter.format(totalSummary ? totalSummary.shopify_net_return : 0),
        //     label: "Shopify Net Discount",
        // },
        {
            textColorClass: 'text-danger',
            value: formatter.format(totalSummary ? totalSummary.total_difference : 0),
            label: "Difference with Shopify",
        },
    ];

    return (
        <div className="row">
            {widgetsData.map((widget, index) => (
                <Widget
                    key={index}
                    textColorClass={widget.textColorClass}
                    value={widget.value}
                    label={widget.label}
                />
            ))}
        </div>
    );
};

export default DiscountTotalSummary;
